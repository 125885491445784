import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.25.0_next@14.2.17_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1._id3e354ic3wictaduu3zlgyojm/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/next-intl@3.25.0_next@14.2.17_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1._id3e354ic3wictaduu3zlgyojm/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/react-bootstrap@2.10.2_@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-bootstrap/esm/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/.pnpm/react-bootstrap@2.10.2_@types+react@18.3.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/react-bootstrap/esm/Navbar.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Menu/Menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Menu/MobileTabItem.tsx");
